<template>
  <div class="home">
    <Sidebar class="home_Sidebar" :loading="loading" :sidebarData="sidebarData" :handleChangePlot="handleChangePlot" />
    <div class="home_Table">
      <div class="home_Table-btn">
        <div class="home_Table-label">{{ location }}</div>
        <el-button type="primary" @click="handleOpenAdd(1)">新增</el-button>
      </div>
      <Table
        :loading="loading"
        :rowHeader="rowHeader"
        :data="data"
        :pageShow="pageShow"
        :pageObj="pageObj"
        :handleSizeChange="handleSizeChange"
        :handleCurrentChange="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="`${
        dialogType === 1 ? '新增' : dialogType === 2 ? '编辑' : '删除'
      }设备`"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div v-if="dialogVisible">
        <AddDevice
          v-if="dialogType === 1"
          :handleClose="handleClose"
          :sidebarData="sidebarData"
          :getDeviceSave="getDeviceSave"
          :mode="mode"
          :hotList="hotList"
        />
        <EditDevice
          v-if="dialogType === 2"
          :handleClose="handleClose"
          :sidebarData="sidebarData"
          :getDeviceUpdate="getDeviceUpdate"
          :mode="mode"
          :deviceObj="deviceObj"
          :hotList="hotList"
        />
        <DeleteAny
          v-if="dialogType === 3"
          :handleClose="handleClose"
          :name="deviceObj.name"
          :typeName="typeName"
          :getDelFunc="getDeviceDelete"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    Sidebar: () => import("./components/Sidebar"),
    Table: () => import("@/components/Table"),
    AddDevice: () => import("./components/AddDevice"),
    EditDevice: () => import("./components/EditDevice"),
    DeleteAny: () => import("@/components/DeleteAny"),
  },
  data() {
    return {
      loading: true,
      pageShow: true,
      deptId: JSON.parse(window.sessionStorage.getItem("userInfo")).deptId,
      sidebarData: [],
      hotList: [], // 设备服务器
      orgId: 0,
      page: 1,
      size: 10,
      pageObj: {
        current: 1,
        total: 0,
      },
      rowHeader: [
        {
          label: "序号",
          width: "50",
          render: (h, params) => {
            return <span>{params.index + (this.pageObj.current - 1) * this.pageObj.size}</span>;
          },
        },
        {
          prop: "name",
          label: "设备名称",
        },
        {
          prop: "deviceId",
          label: "设备编号",
        },
        {
          prop: "typeName",
          label: "设备型号",
        },
        {
          prop: "tag",
          label: "设备类型",
        },
        {
          label: "园区地块",
          render: (h, params) => {
            return (
              <span title={`${params.row.orgParentName} - ${params.row.orgName}`}>
                {`${params.row.orgParentName} - ${params.row.orgName}`}
              </span>
            );
          },
        },
        {
          prop: "btn",
          label: "操作",
          render: (h, params) => {
            return (
              <div class="btn_box">
                <span class="btn" onClick={() => this.handleOpenAdd(2, params.row)}>
                  编辑
                </span>
                <span class="btn" onClick={() => this.handleOpenAdd(3, params.row)}>
                  删除
                </span>
              </div>
            );
          },
        },
      ],
      data: [],
      dialogVisible: false,
      dialogType: 1,
      mode: {
        typeArr: [
          {
            name: "环境监测",
            id: "1",
          },
          {
            name: "环境控制",
            id: "2",
          },
          {
            name: "视频监控",
            id: "3",
          },
        ],
        model1List: [
          {
            name: "空气温湿度传感器",
            id: 513,
          },
          {
            name: "雨量传感器",
            id: 514,
          },
          {
            name: "光照传感器",
            id: 515,
          },
          {
            name: "土壤温湿度传感器",
            id: 516,
          },
          {
            name: "二氧化碳传感器",
            id: 517,
          },
          {
            name: "土壤EC值传感器",
            id: 518,
          },
          {
            name: "土壤PH值传感器",
            id: 519,
          },
          {
            name: "智能气象站网关",
            id: 1282,
          },
        ],
        model2List: [
          {
            name: "NB水阀控制器",
            id: 545,
          },
          {
            name: "智能可视放风机",
            id: 769,
          },
          {
            name: "水阀控制器",
            id: 1025,
          },
          {
            name: "雾化控制器",
            id: 1026,
          },
          {
            name: "卷帘控制器",
            id: 1027,
          },
          {
            name: "补光灯控制器",
            id: 1028,
          },
          {
            name: "万能控制器",
            id: 1794,
          },
        ],
        model3List: [
          {
            name: "高清监控摄像头",
            id: 33,
          },
        ],
      },
      deviceObj: {},
      typeName: "设备",
      location: "",
    };
  },
  created() {
    this.$emit("tabIndex", "1");
    this.getDeptTree();
    this.mqttServer();
  },
  methods: {
    // 设备服务器
    async mqttServer() {
      const { data } = await this.$api.mqttServer();
      this.hotList = data;
    },
    // 查询园区地块
    async getDeptTree() {
      const { data } = await this.$api.getDeptTree({ id: this.deptId });
      this.sidebarData = data;
      this.location = `${this.sidebarData[0].children[0].parentName}-${this.sidebarData[0].children[0].label}`;
      this.orgId = this.sidebarData[0].children.length ? this.sidebarData[0].children[0].id : this.sidebarData[0].id;
      this.getDeviceList();
    },
    // 查询设备列表
    async getDeviceList() {
      this.loading = true;
      const { data } = await this.$api.getDeviceList({
        orgId: this.orgId,
        page: this.page,
        size: this.size,
      });
      this.data = data.records;
      this.pageObj = data;
      this.loading = false;
    },
    // 添加设备
    async getDeviceSave(deviceObj) {
      const res = await this.$api.getDeviceSave(deviceObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getDeviceList();
      this.handleClose();
    },
    // 更新设备
    async getDeviceUpdate(deviceObj) {
      const res = await this.$api.getDeviceUpdate(deviceObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getDeviceList();
      this.handleClose();
    },
    // 删除设备
    async getDeviceDelete() {
      const res = await this.$api.getDeviceDelete(this.deviceObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getDeviceList();
      this.handleClose();
    },
    // 条数
    handleSizeChange(val) {
      this.size = val;
      this.getDeviceList();
    },
    // 页数
    handleCurrentChange(val) {
      this.page = val;
      this.getDeviceList();
    },
    // 切换地块
    handleChangePlot(row) {
      console.log(row);
      this.page = 1;
      this.size = 10;
      this.location = `${row.parentName}-${row.label}`;
      this.orgId = row.id;
      this.getDeviceList();
    },
    // 打开dialog
    async handleOpenAdd(type, row) {
      if (type !== 1) {
        this.deviceObj = row;
      }
      this.dialogType = type;
      this.dialogVisible = true;
    },
    // 关闭dialog
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  text-align: right;
  &_Sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20%;
    margin-right: 20px;
    overflow-x: hidden;
    text-align: left;
  }
  &_Table {
    display: inline-block;
    width: 78%;
    padding: 20px;
    min-height: calc(100vh - 110px);
    box-sizing: border-box;
    margin: 20px 20px 20px 0;
    background-color: #fff;
    text-align: center;
  }
  &_Table-btn {
    position: relative;
    margin-bottom: 20px;
    text-align: right;
  }
  &_Table-label {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
  }
}
</style>
